import { Iframe } from '@almbrand/iframe';
import { useState } from 'react';

export interface IframeComponentProps {
	iframeTitle: string;
	iframeUrl: string;
	contentId: number;
	height?: number;
	width?: number;
}

export const IframeComponent: React.FC<IframeComponentProps> = ({ iframeTitle, iframeUrl, contentId }) => {
	const [iframeLoaded, setIframeLoaded] = useState(false);

	return (
		<>
			{!iframeLoaded && <h1>Loading...</h1>}
			<Iframe
				id={contentId}
				src={iframeUrl}
				title={iframeTitle}
				onIframeLoaded={() => setIframeLoaded(true)}
			/>
		</>
	);
};
